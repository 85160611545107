import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class Popular extends React.Component {
  state = {
    search: ''
  };
  search = (value) => {
    this.setState({ search: value.target.value });
  };
  render() {
    let c = 0;
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;
    return (
      <>
        {posts.length > 0 &&
        <>
          <div className="search-post ca-shadow-sm rounded bg-white widget">
            <div className="input-group ca-shadow-sm rounded-2">
              <input type="text" className="form-control border-0 rounded-2" placeholder="Search" value={this.state.search} onChange={this.search} />
              <div className="input-group-append bg-white rounded-2">
                <button className="btn ca-btn text-secondary bg-white" type="submit"><i className="fa fa-search" /></button>
              </div>
            </div>
          </div>
          <div className="related-post ca-shadow-sm rounded bg-white widget p-2">
            {
              posts && posts.map(({ node: post }, i) => {
                if (post.id === this.props.activePost) return null;
                c = c + 1;
                if (c > 4) return null;
                if (post.frontmatter.title.toLowerCase().indexOf(this.state.search.toLowerCase()) === -1) return null;
                return (
                  <div className="num-related-post" key={i}>
                    <div className="rel-img">
                      <Link to={post.fields.slug}>
                        <img className="image-fluid" src={`${post.frontmatter.featuredimage.childImageSharp ? post.frontmatter.featuredimage.childImageSharp.fluid.src : post.frontmatter.featuredimage.childImageSharp}`} alt="" />
                      </Link>
                    </div>
                    <div className="rel-cont">
                      <Link to={post.fields.slug}>
                        <h3 className="rel-post-title">
                          {post.frontmatter.title}
                        </h3>
                      </Link>
                      <p className="rel-post-desc" style={{maxHeight: "40px", overflow: "hidden"}}>{post.excerpt}</p>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </>}
      </>
    )
  }
}

Popular.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default (props) => {
  return (
    <StaticQuery
      query={graphql`
      query PopularQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
      render={(data, count) => <Popular data={data} count={count} activePost={props.activePost} />}
    />
  )
}
