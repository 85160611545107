import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import Popular from '../components/Popular';
import { Helmet } from 'react-helmet';

export const BlogPostTemplate = ({content, contentComponent, description, title, helmet, image, id}) => {
  const PostContent = contentComponent || Content;
  return (
    <main role="main">
      {helmet || ''}
      <div className="about-main">
        <div className="inner-wrap" >
          <div className="post text-center" style={{"backgroundImage": `url(${image ? image.fluid.src : image})`}}>
            <div className="container">
              <h1 className="text-white">{title}</h1>
            </div>
          </div>
          <div className="post-wrap">
            <div className="container">
              <div className="row py-5 px-2">
                <div className="col-md-8 pr-4 text-justify blog-content">
                  <PostContent content={content} />
                </div>
                <div className="col-md-4">
                  <div className="sidebar-wrap pt-3">
                    <Popular activePost={id} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <BlogPostTemplate
        id={post.id}
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        image={post.frontmatter.featuredimage.childImageSharp}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 1600, quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
      }
    }
  }
`
